import { ApiValidationErrorResponse, OldValidationErrorResponse, isOldApiValidationError, VeriClockValidationAjvError, VeriClockValidationError, VeriClockValidationYupError, isApiValidationError } from '../../../vericlock_api/src/validation/ApiValidationErrorResponse';
import { FieldValues, UseFormReturn } from 'react-hook-form';

type ReactHookFormSetErrorFunction<T extends FieldValues> = UseFormReturn<T>['setError']
    // setError(name: IsFlatObject<FormValues> extends true ? Extract<keyof FormValues, string> : string, type: MultipleFieldErrors): void |
    // setError(name: IsFlatObject<FormValues> extends true ? Extract<keyof FormValues, string> : string, type: string, message?: Message): void |
    // setError(name: ManualFieldError<FormValues>[]): void;


//NOTE - ReactHookFormSetErrorFunction<any> => the ts in react-hook-form will id the correct string based on the actual object structure
//this helper function can't really get that benefit without more effort or ...?
//yup response injection into a formHook
function injectYupErrorIntoForm(err:VeriClockValidationYupError, setError:ReactHookFormSetErrorFunction<any>)
{
    if(err.path)
        setError(err.path, {
            type: 'notMatch', 
            message: err.message
        });
    else
        console.warn('err.path missing from yup err', err); //not sure when this could occur

    for(var i=0; i < err.inner.length; i++)
    {
        injectYupErrorIntoForm(err.inner[i], setError); //recurse with nested errors
    }
}
function injectValidationErrorsIntoForm(errors:VeriClockValidationAjvError[]|VeriClockValidationError[], setError:ReactHookFormSetErrorFunction<any>)
{
    // console.warn('we probably need to group by property, and inject multiple as an array? otherwise subsequent props will get overwritten');

    // const errorMap = errors.reduce<Record<string,string[]>>( (prev, e, index, all) => {
        
    //     if(prev[e.path] === undefined)
    //         prev[e.path] = [];

    //     prev[e.path].push(e.message);
    //     return prev;
    // },{});

    // Object.keys(errorMap).forEach(errPath => {
    //     let errs = errorMap[errPath];
    //     if(errs.length > 1)
    //         console.warn('errs.length > 1 fired - needs addressing'); //maybe via a manual, manual2,manual3, etc..type value?
    //     setError(errPath, {
    //         type: 'manual',
    //         message: errs[0]
    //     })
    // })

    errors.forEach(e => setError(e.path, {
        type: e.type,
        message: e.message
    }))
    
}
function injectionOldValidationErrorsInfoForm(errors:OldValidationErrorResponse['validationErrors'], setError:ReactHookFormSetErrorFunction<any>)
{
    if(!errors)
        return;
    errors.forEach(e => setError(e.fullProperty, {
        type: 'oldError',
        message: e.humanFriendlyError ? e.humanFriendlyError : e.error
    }))  
}

//helper function to process our server response and inject into forms
// removeErrPathPrefix
export function serverYupValidationResponseToReactHookFormErrors<T extends FieldValues>(res:ApiValidationErrorResponse|OldValidationErrorResponse , setError:ReactHookFormSetErrorFunction<T>, options?: {
    // errParamForNonValidationErrors?:string, //not used?
    removeErrPathPrefix?: string
}):boolean
{   
    const {
        removeErrPathPrefix,
    } = options ?? {};
    
    if(isOldApiValidationError(res))
    {
        injectionOldValidationErrorsInfoForm(res.validationErrors, setError);
    }
    else if(res.isYup)
    {
        injectYupErrorIntoForm(res.yup, setError);
        return true;
    }
    else if(res.errors)
    {
        let errs = removeErrPathPrefix ? res.errors.map(e => {
            if(e.path.indexOf(removeErrPathPrefix) === 0)
            {
                //splice off the lenght of the prefix, do not use replace
                const newPath = e.path.slice(removeErrPathPrefix.length);
                return {
                    ...e,
                    path: newPath
                }
            }
            return e;
        }): res.errors;

        injectValidationErrorsIntoForm(errs, setError)
        return true;
    }
    
    
    return false;
}

export function vFormHandlerApiError({
    err, setError,
    unknownErrorParamPathPrefix="/unknown"
}:{
    err: any, //msg from api, or possibly elsewhere
    setError: ReactHookFormSetErrorFunction<any>,
    unknownErrorParamPathPrefix?: string
})
{
    if(isApiValidationError(err))
        serverYupValidationResponseToReactHookFormErrors(err, setError);
    else if(unknownErrorParamPathPrefix) {
        const errMsg = err.userFriendlyMessage ? err.userFriendlyMessage : (err.message ? err.message : err.toString());
        setError(unknownErrorParamPathPrefix,{ type: 'unknown',message: errMsg });
    }
}
//non-form, error response message extraction
export function getErrorMessageFromServerError(err: any):string
{
    if(typeof(err) === 'object')
    {
        if(err.isYup)
        {
            if(err.yup.message)
                return err.yup.message;            
        }
        if(err.humanFriendlyError)
            return err.humanFriendlyError;
        if(err.message)
            return err.message;        
    }
    console.warn(err);
    return "Unknown error - please refresh and try again";
}
