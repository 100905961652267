import { type FieldPath } from "react-hook-form";
import { cloneDeep, get as lodashGet, set as lodashSet } from 'lodash'

// export const DecimalRegex = /(^\d*\.\d+$)|(^\d+\.?\d*$)/  //not easy to get right... negative numbers...
export const IntegerRegex = /^[+-]?\d+$/ //allow plus/minus sign as first char

export type CoerceBaseType = Record<string,unknown>;
//borrow rhf's FieldPath, which can turn a string of 'a.b.c' into an existance check inside the T object
//todo: for subschema, could we take the key and remove it so we get sub keys?
export type CoercionSchema<T extends CoerceBaseType=CoerceBaseType> = { key: FieldPath<T>, coercsion: CoerceBase|CoercionSchema }[]

type BlankToOptions = undefined|null|number;

export function stringToInteger(value:unknown, useBlankTo:boolean, blankTo:BlankToOptions)
{
    if(typeof(value) !== 'string')
        return value; 
    const tmpVal = value.trim();
    
    if(tmpVal.length === 0) {
        if(useBlankTo)
            return blankTo; 
        else
            return value; //0 length == error 
    }

    if(!IntegerRegex.test(tmpVal))  //no integer, will produce type failure
        return value;         
    return parseInt(tmpVal);
}
//.343453  // (^\d?\.\d+$)|(^\d+\.?\d?$)
// 234234.
// 234
// 0.2342
// 123123.0003
export function stringToDecimal(value:unknown, useBlankTo:boolean, blankTo:BlankToOptions, multiplyBy:number, doRounding:boolean)
{
    if(typeof(value) === 'number')
    {
        const convertedNumber = (value as number)*multiplyBy; 
        return doRounding ? Math.round(convertedNumber) : convertedNumber;
    }
    if(typeof(value) !== 'string') {
        console.warn(`stringToDecimal(): typeof(value) !== 'string' => true`, { value, useBlankTo, blankTo, multiplyBy });
        return value; //not a string...and not a number through form-non-running-its-monkey-biz-yet...just return the value as is and let validation deal with it...we can't coerse
    }

    
    const tmpVal = value.trim();

    if(tmpVal.length === 0) {
        if(useBlankTo)
            return blankTo; 
        else
            return value; //0 length == error 
    }

    const decimalVal = parseFloat(tmpVal); //rely on parseFloat to get the job done
    if(isNaN(decimalVal)) //if NaN, return orig to generate error in validation lib
        return value;
    return doRounding ? Math.round(decimalVal*multiplyBy) : decimalVal*multiplyBy;
}

export abstract class CoerceBase 
{
    protected blankTo?:BlankToOptions
    protected usingBlankTo:boolean
    protected multiplyBy:number
    protected doRounding:boolean
    constructor()
    {
        this.usingBlankTo = false;
        this.multiplyBy = 1;
        this.doRounding = false;
    }
    BlankTo(val:BlankToOptions)
    {
        this.blankTo = val;
        this.usingBlankTo = true;
        return this;
    }
    MultiplyBy(val:number)
    {
        this.multiplyBy = val;
        return this;
    }
    Round()
    {
        this.doRounding = true;
        return this;
    }

    abstract coerce(allValues:CoerceBaseType, value:unknown):unknown
}
class ToIntegerCoercer extends CoerceBase
{
    coerce(allValues:CoerceBaseType, value:unknown)
    {
        return stringToInteger(value, this.usingBlankTo, this.blankTo);
    }
}
class ConvertDollarsToCents extends CoerceBase 
{
    coerce(allValues:CoerceBaseType, value:unknown)
    {
        const dollarsFloat = stringToDecimal(value, this.usingBlankTo, this.blankTo, this.multiplyBy, this.doRounding);
        if(typeof(dollarsFloat) === 'number')
            return Math.round(dollarsFloat * 100);
        
        //not number, return the value to fail on validation
        return value;
    } 
}
class KeepAsStringCoercer extends CoerceBase
{
    coerce(allValues:CoerceBaseType, value:unknown)
    {
        if(typeof(value) === 'string')
        {
            const tmpVal = value.trim();
            if(tmpVal === '' && this.usingBlankTo)
                return this.blankTo;
        }
        return value;
    }
}

class NullToEmptyStringCoercer extends CoerceBase
{
    coerce(allValues:CoerceBaseType, value:unknown)
    {
        if(value === null)
        {
            return '';
        }
        return value;
    }
}
class ToDecimalCoercer extends CoerceBase
{
    coerce(allValues:CoerceBaseType, value:unknown)
    {
        return stringToDecimal(value, this.usingBlankTo, this.blankTo, this.multiplyBy, this.doRounding);
    }
}
class ToObjectToNullIfAllEmptyCoercer<T extends CoerceBaseType=CoerceBaseType> extends CoerceBase
{
    keys: FieldPath<T>[]|undefined
    constructor(keys?: FieldPath<T>[])
    {
        super();
        this.keys = keys;
    }
    coerce(allValues:CoerceBaseType, value:T|null|undefined) //value should be expected type T, but in theory doesn't have to be as anything could get injected
    {
        if(typeof(value) === 'object' && value !== null)
        {
            if(this.keys === undefined) //ALL keys, not specific ones
            {
                const objKeys = Object.keys(value);
                for(let i=0; i < objKeys.length; i++)
                {
                    let k = objKeys[i];
                    if(value[k] !== "")
                        return value;   //quick exit, one of the fields is not blank, so we are not returning null
                }
            }
            else 
            {
                for(let i=0; i < this.keys.length; i++)
                {
                    let k = this.keys[i];// as string; //as string cast as TS isn't picking it up
                    if(value[k] !== "")
                        return value;   //quick exit, one of the fields is not blank, so we are not returning null
                }
            }
            //fall through to here, then passed the blank tests, so we return null
            return null;
        }
        //fall through to here, not an object or null, so we return value as is - will get caught be other validation layer
        return value;
    }
}
class ArrayOfObjectsCoercer<T extends CoerceBaseType=CoerceBaseType> extends CoerceBase 
{
    key: FieldPath<T>
    coercer: CoerceBase
    constructor(key: FieldPath<T>, coercer:CoerceBase)
    {
        super();
        this.key = key;
        this.coercer = coercer;
    }
    coerce(allValues:CoerceBaseType, value:T[]|null|undefined) //value should be expected type T, but in theory doesn't have to be as anything could get injected
    {
        if(Array.isArray(value))
        {
            for(let i=0;i<value.length;i++)
            {
                let item = value[i];
                const newValue = this.coercer.coerce(item,item[this.key]);
                value[i][this.key] = newValue as any;
            }
        }

        return value;
    }
}

//base type constructors
export function KeepAsString() {
    return new KeepAsStringCoercer();
}
export function NullToEmptyString() {
    return new NullToEmptyStringCoercer();
}
export function ToInteger() {
    return new ToIntegerCoercer();
}
export function DollarsToCents() { //note - this is not tested
    return new ConvertDollarsToCents();
}
export function ToDecimal() {
    return new ToDecimalCoercer();
}
export function ToObjectToNullIfAllEmpty<T extends CoerceBaseType=CoerceBaseType>(keys:FieldPath<T>[])
{
    return new ToObjectToNullIfAllEmptyCoercer(keys);
}
export function IterateArrayOfObjects<T extends CoerceBaseType=CoerceBaseType>(key:FieldPath<T>, coercer:CoerceBase )
{
    return new ArrayOfObjectsCoercer(key,coercer);
}
export function CoerceValues(values:Record<string,unknown>, coercionSchema:CoercionSchema):Record<string, unknown>
{
    let clone = cloneDeep(values);

    for(let i=0; i < coercionSchema.length; i++)
    {
        let valueSchema = coercionSchema[i];
        let val = lodashGet(clone, valueSchema.key);
        
        if(Array.isArray(valueSchema.coercsion)) //points to an array, which is an array of coercison schemas...
        {
            if(Array.isArray(val))
            {
                let newValArray:unknown[] = [];
                for(let v of val)
                {
                    const subVal = CoerceValues(v, valueSchema.coercsion)
                    newValArray.push(subVal);
                }
                lodashSet(clone, valueSchema.key, newValArray);
            }
            else if(val !== undefined) { //note - if we want this optional, we can add another option to the coercion schemea def - used in customfield initially in a schemea used where this is sometimes not present
                throw new Error('coercison with sub schema on non-array type not allowed'); //built assuming a secondary Coercison Schema that points to an array of the type
            }
        }
        else 
        {
            let newVal = valueSchema.coercsion.coerce(clone, val);
            if(val === undefined && newVal === undefined)
                continue; //if working on nested values like key: "thing.subkey" and thing is null, we don't want to set thing to { subkey: undefined} so we abort here
            lodashSet(clone, valueSchema.key, newVal); //update value in object
        }
    }

    return clone;
}