import React from 'react'
import { useUID } from 'react-uid'
import { VFormCommonProps, VFormCommonWrapper } from './VFormCommon'

const formTextInputClassName = 'form-control';
const formTextInputStyle:React.CSSProperties = {}
const formTextInputStyleInlineWidth:React.CSSProperties = 
{
    ...formTextInputStyle,  
    display:'inline-block' 
};
// const test:React.FC<HTMLInputElement> = (props) => {
    
//     return <input onBlur={e => {} } />
// }

interface VFormTextInputProps extends VFormCommonProps<HTMLInputElement>
{
    fieldIsPassword?:boolean               //if true, this should be a password text field //could piggy back off of type? though we're ignoring type for now
    inlineWidth?:number|string                     //number - px, string , whatever style allows

    // type?:never
}
export const VFormTextInput:React.FC<VFormTextInputProps> = ({

    //extract and disable
    autoComplete,//="off", //"off" insufficient - below we use the random name if we haven't speciied how this should behave
    autoCorrect="off",
    autoCapitalize="off",
    type,
    // onBlur:ourOnBlur,
    // id,
    ...props}) => {

    const myUniqueId = 'input_' + useUID();

    if(autoComplete === undefined)
    {
        //if we haven't overridden it, then default to off, or new-password if this is a password field
        autoComplete = props.fieldIsPassword ? "new-password" : "off"; //'new-field-' + props.fieldName; //"new-" + myUniqueId ?
    }

    const extraProps = {
        autoComplete,
        autoCorrect,
        autoCapitalize,
        disabled: props.disabled,
        // id
    }

    //why not just pass style through?  To limit the styles that can be applied for increased consistency
    //should only need a few types of controls
    const extraStyling:React.CSSProperties = props.inlineWidth === undefined ? 
        formTextInputStyle : 
        {...formTextInputStyleInlineWidth , width: props.inlineWidth }

    //type == search to trick autocomplete systems....not sure of full consequence yet
    return <VFormCommonWrapper inputId={myUniqueId} {...extraProps} {...props} >
        {({isError, ref, onChange, onBlur, name }) => {
            function onChangeWedge(a:any)
            {
                return onChange(a);
            }
            return <input 
                id={myUniqueId} 
                type={type ? type : (props.fieldIsPassword ? "password" : "search") }
                className={formTextInputClassName} 
                style={{...formTextInputStyle, ...extraStyling}} 
                placeholder={props.placeholder}
                {...extraProps}
                ref={ref}
                // onChange={onChange}
                onChange={onChangeWedge}                
                onBlur={onBlur}
                name={name} 
            />
        }}
    </VFormCommonWrapper>
}