import React, { DetailedHTMLProps, FormHTMLAttributes, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { DeepMap, FieldError, FieldValues, useFormContext } from 'react-hook-form';
import { errorMessageMap, VFormErrorText } from '../VFormErrorText';
import { VRow, VCol } from '../VGrid';


const lodashGet = require('lodash/get');

//can control common behavior
export function VForm(props: {
    formRef?:React.Ref<any>
    onSubmit?: DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>['onSubmit']
    children: React.ReactNode
}) 
{
    return <VFormErrorProvider>
        <form onSubmit={props.onSubmit} autoComplete="off" ref={props.formRef}>
            {props.children}
        </form>
    </VFormErrorProvider>
}

const formGroupClassName = 'form-group';
export const VFormGroup:React.FC<PropsWithChildren> = ({children}) => {
    return <VRow className={formGroupClassName}>{children}</VRow>
}
export const VFormCol:React.FC<{
    className?:string,
    xs?:number,
    sm?:number,
    md?:number,
    lg?:number
} & PropsWithChildren> = ({children, ...props}) => {
    return <VCol {...props}>{children}</VCol>
}
//Todo - export a renderProp function and invoke? For better error customization, but really, its just a list of normally not displayed errors
//unlikely to display normally
function isFieldError(e:Partial<FieldError>):e is FieldError
{
    if(typeof(e.type) === "string" && typeof(e.message) === "string")
        return true;
    return false;
}
function makeErrorPaths(basePath:string, errors:DeepMap<FieldValues, FieldError>):string[]
{
    let pathArray:string[] = [];
    let keys = Object.keys(errors);
    keys.forEach(k => {

        let v = errors[k];
        const loopKey = basePath + (basePath.length === 0 ? k : `.${k}`); //add param to base path
        if(isFieldError(v))
        {
            pathArray.push(loopKey);
        }
        else {
            const subKeys = makeErrorPaths(loopKey, v);
            pathArray = pathArray.concat(subKeys);
        }
    });

    return pathArray;
}   
//render all errors that do not have registered error displays
// example with render props
// <VFormErrors ...>{(stuff) => <div className="alert alert-danger">{stuff}</div>}</VFormErrors>
export const VFormErrors:React.FC<{
    wrapperComponent?:React.ComponentType<PropsWithChildren>
    children?: (errorsJSx:JSX.Element[]) => React.ReactNode
}> = (props) =>
{
    const Wrapper = props.wrapperComponent;
    const { formState: { errors } } = useFormContext();
    const errorFieldNames = makeErrorPaths('', errors);
    // const errorFieldNames = Object.keys(errors);
    // const error
    const registeredFields = useGetErrorRegisteredFields()
    const fieldsWithNoDisplayHome = errorFieldNames.filter( fieldName => !registeredFields[fieldName]);
    
    if(fieldsWithNoDisplayHome.length === 0)
    {
        return null;
    }

    //todo - could expose render callback to do more custom rendering
    //
    // props.children({errors}) ... then it can display errors as desired
    // or expose the above code as a specific hook to get the errors and then can be processed in the component body more easily
    let errList = fieldsWithNoDisplayHome.map((f,i) => {
        let e = lodashGet(errors, f) as FieldError;
        return <div title={f} key={i}>{e.message}</div>
    })

    //wrapper mode - used by vform modal
    if(Wrapper)
        return <Wrapper><div style={{color:"red", textAlign:"left"}}>{errList}</div></Wrapper>

    //used by vform 
    if(!props.children)
        return null;

    return <>{props.children(errList)}</>
}

export type VFormErrorContextType = {
    registerErrorFieldForDisplay(fieldName:string): void
    unRegisterErrorFieldForDisplay(fieldName:string): void
    getErrorRegisteredFields(): Record<string,boolean>
}
const VFormErrorContext = React.createContext<VFormErrorContextType|null>(null);
export const VFormErrorProvider:React.FC<PropsWithChildren> = (props) =>
{
    // const [fieldNames,setFieldNames] = useState<Record<string,boolean>>({});
    const [, setTriggerUpdate] = useState(0);
    const fieldNames = useRef<Record<string,boolean>>({});
    const errorCtx:VFormErrorContextType = useMemo(() => {
        return {
            registerErrorFieldForDisplay: (fieldName:string) => {
                if(!fieldNames.current[fieldName]) { 
                    // setFieldNames(old => {
                    //     return {...old, [fieldName]: true }
                    // })
                    fieldNames.current[fieldName] = true;
                    setTriggerUpdate(old => old+1);
                }
            },
            unRegisterErrorFieldForDisplay: (fieldName:string) => {
                if(fieldNames.current[fieldName]) { 
                    // setFieldNames(old => {
                    //     return {...old, [fieldName]: false }
                    // })
                    fieldNames.current[fieldName] = false;
                    setTriggerUpdate(old => old+1);
                }
            },
            getErrorRegisteredFields: () => fieldNames.current
        }
    },[]);
    return <VFormErrorContext.Provider value={errorCtx}>
        {props.children}
    </VFormErrorContext.Provider>
}
export function useVFormErrorContext()
{
    const ctx = useContext(VFormErrorContext);
    if(ctx === null)
        throw new Error('useVFormErrorContext must be invoked from within a VFormErrorProvider component');
    return ctx;
}

export function useGetErrorRegisteredFields()
{
    const ctx = useVFormErrorContext();
    return ctx.getErrorRegisteredFields();
}
// export function useIsErrorFieldRegistered(name:string)
// {

// }
// export function useAllRegisteredErrorFields()
// {
//     useContext()
// }
function useRegisterErrorDisplay(fieldName:string)
{
    const { registerErrorFieldForDisplay, unRegisterErrorFieldForDisplay } = useVFormErrorContext();
    useEffect(() => {
        registerErrorFieldForDisplay(fieldName)
        return () => {
            unRegisterErrorFieldForDisplay(fieldName)
        }
    },[fieldName, registerErrorFieldForDisplay, unRegisterErrorFieldForDisplay]);
}

export function VFormErrorDisplay(props:{
    fieldName:string
    suppressed?:boolean|undefined
    fieldError?: {                           //pass through to form error component - reconsider this structure and instead inject into error context more directly?
        overrideMessage?: errorMessageMap;
        defaultMessage?: string
    }
})
{
    useRegisterErrorDisplay(props.fieldName);
    const { formState: { errors } } = useFormContext();    
    const extractedError = lodashGet(errors, props.fieldName);
    const isError = extractedError !== undefined; 

    if(!isError || props.suppressed)
        return null;
    return <VFormErrorText formError={extractedError} {...props.fieldError} noRenderOnNoError={true}/>
}

const FormPrefixProvider = React.createContext<string|undefined>(undefined);
export function FormPrefix(props:{
    prefix?:string
    children:React.ReactNode
})
{
    return <FormPrefixProvider.Provider value={props.prefix}>
        {props.children}
    </FormPrefixProvider.Provider>
}
export function useFormPrefix(fieldName:string)
{
    const ctx = useContext(FormPrefixProvider);
    if(ctx === undefined || ctx === null || ctx === '') //missing or empty, just return the field name
        return fieldName;
    return ctx + '.' + fieldName;
}