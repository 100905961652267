import * as React from "react";
import { FieldError } from 'react-hook-form';

export interface errorMessageMap {
    [type:string] : string;
}

export function VFormErrorText(props:{
    formError?: FieldError|undefined,
    overrideMessage?: errorMessageMap,                // If the type matches, the string will replace the error message.
    defaultMessage?: string,                          // If no override message is found, then this string will be used as the default message.
    noRenderOnNoError?:boolean
})
{
    let message;
    if(props.formError)
    {
        if (props.overrideMessage && props.overrideMessage[props.formError.type])
        {
            message = props.overrideMessage[props.formError.type];
        }
        else if (props.defaultMessage)
        {
            message = props.defaultMessage;
        }
        else
        {
            message = props.formError.message
        }
        // return <div style={{color:"red", whiteSpace:'pre-wrap'}}>{message}</div>
    }
    return <VErrorText errorMessage={message} noRenderOnNoError={props.noRenderOnNoError}/>
    // if(props.noRenderOnNoError) //no hidden field if no error 
    //     return null;        
    // return <div style={{visibility:"hidden"}}>&nbsp;</div>;
}

export function VErrorText(props:{
    errorMessage?: string|undefined,
    noRenderOnNoError?:boolean
})
{
    if(props.errorMessage)
    {
        return <div style={{color:"red", whiteSpace:'pre-wrap'}}>{props.errorMessage}</div>
    }
    if(props.noRenderOnNoError) //no hidden field if no error 
        return null;
    return <div style={{visibility:"hidden"}}>&nbsp;</div>;
}