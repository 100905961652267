// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .VModalBody {
    min-height: 200px;
} */

@media (max-width: 991px) { /* md and xs */
    .modal-dialog {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;        
    }
      
    .modal-content {
        height: auto;
        min-height: 100%;
        border-radius: 0;
    }
}

body {
    width: 100%;
    max-width: 100%;
    /* will-change: position; */
}

body.modal-open {
    position: absolute;
    top:    0;
    right:  0;
    bottom: 0;
    left:   0;
}

.modal {
    z-index: 1050; /* match the fade to make stacked modals look better */
}

`, "",{"version":3,"sources":["webpack://./webApp/src/ui/components/core/VModal/VModal.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH,4BAA4B,cAAc;IACtC;QACI,WAAW;QACX,YAAY;QACZ,SAAS;QACT,UAAU;IACd;;IAEA;QACI,YAAY;QACZ,gBAAgB;QAChB,gBAAgB;IACpB;AACJ;;AAEA;IACI,WAAW;IACX,eAAe;IACf,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,SAAS;IACT,SAAS;AACb;;AAEA;IACI,aAAa,EAAE,sDAAsD;AACzE","sourcesContent":["/* .VModalBody {\n    min-height: 200px;\n} */\n\n@media (max-width: 991px) { /* md and xs */\n    .modal-dialog {\n        width: 100%;\n        height: 100%;\n        margin: 0;\n        padding: 0;        \n    }\n      \n    .modal-content {\n        height: auto;\n        min-height: 100%;\n        border-radius: 0;\n    }\n}\n\nbody {\n    width: 100%;\n    max-width: 100%;\n    /* will-change: position; */\n}\n\nbody.modal-open {\n    position: absolute;\n    top:    0;\n    right:  0;\n    bottom: 0;\n    left:   0;\n}\n\n.modal {\n    z-index: 1050; /* match the fade to make stacked modals look better */\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
