
//Note - could do something different to auto-gen this...

import { get as lodashGet } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Glyphicon, Nav, NavItem, Tab, TabContainer } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { VBetterButton } from "../VButton";
import { stringMap } from "aws-sdk/clients/backup";

//TabLink and Container container wrapped in this, passes down tab name
type VFormTabContextType = {
    tabEventKey: string
}

//All Tabs wrapped in this, passes down registration funciton to register tab eventKey->datafield key for error reporting
type VFormTabsContext = {
    registerFieldInTab: (tabEventKey:string, fieldName:string) => void
    tabEventKeyToErrorObjectKeys: Record<string, string[]>
}
const VFormTabContext = React.createContext<VFormTabContextType|null>(null);
const VFormTabsContext = React.createContext<VFormTabsContext|null>(null);

//Note - this will silently do nothing if tabContext/tabsContext are both not defined
//if 1 is defined, but not the other we throw an error, assuming a developer mistake
//if we need that behavior, lets figure out a good approach
export function useFormTabRegisterField(fieldName:string)
{
    const tabContext = useContext(VFormTabContext);
    const tabsContext = useContext(VFormTabsContext);
    
    if( (tabContext && !tabsContext) || (!tabContext && tabsContext))
        throw new Error('Only one of VFormTabContext and VFormTabsContext are defined; All must be defined, or neither, not just one'); //remove this if we find we do want some form elements outside the tab cont

    const tabEventKey = tabContext?.tabEventKey;
    const registerFieldInTab = tabsContext?.registerFieldInTab;
    
    useEffect(() => {
        if(registerFieldInTab && tabEventKey)
            registerFieldInTab(tabEventKey, fieldName);
    },[registerFieldInTab, fieldName, tabEventKey]);    
}

export function FormTabTitle(props: {
    label:string,
    errorKeys: string[]
})
{
    const { formState: { errors } } = useFormContext();

    let tabHasError = false;
    for(let i=0; i < props.errorKeys.length; i++)
    {
        // if(errors[props.errorKeys[i]])
        let err = lodashGet(errors, props.errorKeys[i]);
        if(err)
        {
           tabHasError = true;
           break; 
        }            
    }
    return <>{props.label}{tabHasError && <sup><Glyphicon glyph="exclamation-sign" style={{color:"red"}}/></sup>}</>
}

export type VFormTabProp = {
    eventKey: string,
    title: string
    component: React.JSXElementConstructor<{
        ignore?:stringMap
        isActive?:boolean   //true if the tab is active
    }>|React.ReactNode
}

export type VFormTabsProps = {
    defaultActiveKey:string 
    id:string 
    animation?: boolean
    tabs: VFormTabProp[],
    tabButton?: {
        eventKey: string,
        title: string|React.ReactNode,
        onClick:()=>void,
    }
}

export function VFormTabs(props:VFormTabsProps)
{
    const [activeKey, setActiveKey] = useState(props.defaultActiveKey);


    return <VFormTabsControlled {...props} activeKey={activeKey} onSelect={(value:any)=>{
        if (typeof value==='string')
            setActiveKey(value)}
    }/>
}

export function VFormTabsControlled(props:VFormTabsProps&{
    activeKey: string,
    onSelect: React.ReactEventHandler<TabContainer>,
})
{
    const { animation=false } = props;
    // const fieldStorageRef = useRef<VFormTabsContext['tabEventKeyToErrorObjectKeys']>({});
    // const storage = fieldStorageRef.current;
    const [storage, setStorage] = useState<VFormTabsContext['tabEventKeyToErrorObjectKeys']>({});
    
    //register the field in the appropriate tab key
    const registerFieldInTab = useCallback((tabEventKey:string, fieldName:string) => {
        setStorage(old => {
           let newVal = {...old}
            if(newVal[tabEventKey] === undefined)
                newVal[tabEventKey] = [];
            newVal[tabEventKey].push(fieldName);
            return newVal;
        });
    },[setStorage]);

    const tabContainerId = props.id;

    //context provider provides the registration function to register a data key to a particular tab
    //and the mapping - which will not re-render on changes due to being a ref
    return <VFormTabsContext.Provider value={{
        registerFieldInTab,
        tabEventKeyToErrorObjectKeys: storage}}>

        <Tab.Container id={tabContainerId} defaultActiveKey={props.defaultActiveKey} activeKey={props.activeKey} onSelect={props.onSelect}>
            <div>
                <div style={{display:"flex"}}>
                    <Nav bsStyle="tabs">
                        {props.tabs.map(t=> {
                            return <NavItem key={t.eventKey} eventKey={t.eventKey}><FormTabTitle label={t.title} errorKeys={storage[t.eventKey] ? storage[t.eventKey] : []}/></NavItem>
                        })}
                    </Nav>
                    { props.tabButton &&
                        <div style={{marginTop:"auto",marginBottom:"auto"}}>
                            <VBetterButton type="button" onClick={props.tabButton.onClick}>{props.tabButton.title}</VBetterButton>
                        </div>
                    }
                </div>
                <div style={{marginTop: "5px"}}>
                    <Tab.Content animation={animation}>
                        {props.tabs.map(t=> {
                            const TabContent = t.component;
                            return <Tab.Pane key={t.eventKey} eventKey={t.eventKey}>
                                <VFormTabContext.Provider value={{tabEventKey: t.eventKey}}>
                                    { typeof(TabContent)=='function' ? <TabContent isActive={props.activeKey === t.eventKey}/> : TabContent}
                                </VFormTabContext.Provider>
                            </Tab.Pane>
                        })}
                    </Tab.Content>
                </div>
            </div>
        </Tab.Container>
    </VFormTabsContext.Provider>
}