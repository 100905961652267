import { AsyncSchema } from 'ajv';
import { compileValidator, isAjvValidationError, makeValidationErrorFromAjvError } from './SchemaCentral';
import * as CoerceLib from './CoerceLib'
import { FieldValues, Resolver } from 'react-hook-form';
import { VeriClockValidationAjvError } from '../../../vericlock_api/src/validation/ApiValidationErrorResponse';
import { yupResolver } from '@hookform/resolvers/yup';

type AjvResolverOptions = {
    preProcess?: (values:any) => any,
    coercsionSchema?: CoerceLib.CoercionSchema
}

type OurYupResolver<T extends FieldValues> = (schema:any, resolveOptions?: AjvResolverOptions) => Resolver<T>
export const ourYupResolver: OurYupResolver<any> = (
    schema,
    resolveOptions = {}
) => {  
    let resolverCallback = yupResolver(schema);
    return async (values, context, options) => {

        const coercedValues = resolveOptions.coercsionSchema ? CoerceLib.CoerceValues(values,resolveOptions.coercsionSchema) : values;            
        return(resolverCallback(coercedValues,context,options))
    }
}

export type PreProcessError = {
    type:"preprocess",
    hookErrors: Record<string, {
        message: string,
        type: string
    }> 
}
function isPreProcessError(err:any):err is PreProcessError
{
    return err && typeof(err) === 'object' && err.type === 'preprocess';
}

type AjvResolver<T extends FieldValues> = (schema:AsyncSchema, resolveOptions?: AjvResolverOptions) => Resolver<T>
export const ajvResolver: AjvResolver<any> = (
    schema,
    resolveOptions = {}
) => {  
    return async (valuesFromUseForm, context, options) => {

        if(options.criteriaMode !== 'all')
            throw new Error('criteriaMode in rhf must be "all" currently');
                  
        // await sleepAsync(500);

        try {      
            let values = valuesFromUseForm; 

            if(resolveOptions.preProcess)
                values = resolveOptions.preProcess(values);

            //coerce values if we have schema, otherwise use form values directly
            const coercedValues = resolveOptions.coercsionSchema ? CoerceLib.CoerceValues(values,resolveOptions.coercsionSchema) : values;
            const validator = compileValidator(schema);
            const validatedObject = await validator(coercedValues);

            return {
                values:validatedObject,
                errors: {},
            };
        } catch (e) {
            if(isPreProcessError(e))
                return {
                    values: {},
                    errors: e.hookErrors
                }

            if(!isAjvValidationError(e)) {
                console.log('Expecting validation error, got unknown error');
                console.error(e);
                throw e; //re-throw it
            }
            const validationError = makeValidationErrorFromAjvError(e);
            const errors = validationErrorToReactHookFormErrors(validationError);

            return {
                values: {},
                errors                
            };
        }
    }
}

function validationErrorToReactHookFormErrors(errList:VeriClockValidationAjvError[])
{
    // let errors:ResolverResult['errors'] = {};
    let errors:Record<string, {
        message: string,
        type: string
    }> = {}
    for(let i=0; i < errList.length; i++)
    {
        let err = errList[i];
        errors[err.path] = {                //when typed as the type from rhf ResolverResult, node build complains
            message: err.message,
            type: err.type
        }
    }

    return errors;
}