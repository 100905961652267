import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { HTMLProps, PropsWithChildren, useCallback } from 'react'
import {UseControllerReturn, useFormContext, Controller} from 'react-hook-form'
import OurTippy from '../OurTippy';
import { errorMessageMap, VFormErrorText } from '../VFormErrorText';
import { VFormErrorDisplay, useFormPrefix } from './VForm';
import { useFormTabRegisterField } from './VFormTabs';
const lodashGet = require('lodash/get');

type RenderHtmlProps<T> = Omit<HTMLProps<any>,'ref'|'onChange'|'onBlur'|'name'>
export type VFormChildRenderProps<T=any> = {
    isError:boolean,
    ref: React.Ref<any>
    onChange: (value: any) => void,
    onBlur: (event: any) => void
    name: string,
    htmlProps: RenderHtmlProps<T>
}

export interface VFormCommonProps<T=any> extends Omit<React.PropsWithChildren<HTMLProps<T>>,'children'>
{
    // fieldIsNumber?:boolean,     //valueAsNumber - used in Register, only works for input type="text"
    fieldLabel:React.ReactNode,
    fieldSuppressLabel?:boolean,
    fieldName: string    //property parameter name in lodash obj form: bort.mop = { bort: { mop: string }}
    fieldSuppressError?:true     //if true, do not render error in standard location below input - it will be rendered elsewhere
    fieldError?: {                           //pass through to form error component - reconsider this structure and instead inject into error context more directly?
        overrideMessage?: errorMessageMap;
        defaultMessage?: string
    },
    fieldUnitLabel?: string,
    helpTip?:React.ReactNode //allow anything - when defined, it is injected into a tippy tool tip via a ? icon
} 
//todo - provide way to stack onChange so we can invoke one passed in and propegate to the form's on change (unless that is 
// an antipattern?  Should we be monitoring change on form fields via useWatch ?

//todo  finish - current issue, the label wraps the control, and so needs 100% width  on it to 
//generally expand to fill grid cells... 
export const LabelWrapper: React.FC<{
    fieldSuppressLabel?:boolean
    fieldLabel: React.ReactNode
} & PropsWithChildren> = ({fieldSuppressLabel,fieldLabel, children}) =>
{
    if(fieldSuppressLabel)
        return <>{children}</>
    return <label style={{width:"100%"}}>{fieldLabel}{children}</label>
}


export const VFormCommonWrapper:React.FC<VFormCommonProps & {
    inputId:string,
    children: (renderProps:VFormChildRenderProps) => React.ReactNode
}> = ({inputId, fieldLabel, helpTip, fieldName:originalFieldName, fieldSuppressLabel=false, fieldSuppressError, fieldError, fieldUnitLabel, children, 
        onBlur:onBlurExternal, //passed in onBlur, we will wrap it 
        // fieldIsNumber,
        ...htmlProps}) => {

    //prefix field, if we are in a composed form where the field name needs a prefx
    const fieldName = useFormPrefix(originalFieldName);

    //registers this field to the tab provider above us, if any, for error detection
    useFormTabRegisterField(fieldName);
    // useForm

    const { formState: { errors }, register } = useFormContext();
    const extractedError = lodashGet(errors, fieldName);
    const isError = extractedError !== undefined; 

    // FYI - valueAsNumber converts empty field to NaN and puts it in the text input... possible more work needed
    const { ref, onChange, onBlur:onBlurReactHookForms, name } = register(fieldName)//, {valueAsNumber: fieldIsNumber});
    
    const onBlurForInput = useCallback((e:React.FocusEvent) => {
        onBlurReactHookForms(e); //call react-hook-forms
        if(onBlurExternal)
        onBlurExternal(e); //call the passed in one, if it was passed in
    },[onBlurReactHookForms, onBlurExternal]);
    return <>
        {/* <LabelWrapper fieldSuppressLabel={fieldSuppressLabel} fieldLabel={fieldLabel}> */}
            {!fieldSuppressLabel && <VFormLabel inputId={inputId} fieldLabel={fieldLabel} helpTip={helpTip} />}
            { fieldUnitLabel && 
                <div className="input-group">
                    {children({isError, ref, onChange, onBlur:onBlurForInput, name, htmlProps })}
                    <span className="input-group-addon">{fieldUnitLabel}</span>
                </div>
            }
            { !fieldUnitLabel && children({isError, ref, onChange, onBlur:onBlurForInput, name, htmlProps })}
            
        {/* </LabelWrapper> */}
        <VFormErrorDisplay fieldName={fieldName} fieldError={fieldError} suppressed={fieldSuppressError} />
    </>
}

type VFormControllerRenderer = (renderProps:{isError:boolean, htmlProps: RenderHtmlProps<any>}, controllerReturnProps:UseControllerReturn) => React.ReactNode;
export const VFormCommonWrapperForController:React.FC<VFormCommonProps & {
    inputId:string,
    children: VFormControllerRenderer
}> = ({inputId, fieldLabel, helpTip, fieldName:originalFieldName, fieldSuppressLabel=false, fieldSuppressError, fieldError, children, ...htmlProps}) => {

    //prefix field, if we are in a composed form where the field name needs a prefx
    const fieldName = useFormPrefix(originalFieldName); 

    useFormTabRegisterField(fieldName); 
    const formMethods = useFormContext();
    if(!formMethods)
        throw new Error('FormProvider must wrap VForm components - useFormContext returned null');
    const { control } = formMethods;
    const renderCallback = useCallback((props:UseControllerReturn) => {
        
        const extractedError = lodashGet(props.fieldState.error, props.field.name);
        const isError = extractedError !== undefined; 
        return <>
            {children({isError, htmlProps}, props)}
            {isError && !fieldSuppressError && <VFormErrorText formError={extractedError} {...fieldError} />}
        </>
    },[children, fieldSuppressError, fieldError, htmlProps]);

    return <>
        {!fieldSuppressLabel && <VFormLabel inputId={inputId} fieldLabel={fieldLabel} helpTip={helpTip} />}
        {/* <LabelWrapper fieldSuppressLabel={fieldSuppressLabel} fieldLabel={fieldLabel}> */}
            <Controller 
                name={fieldName}
                control={control}
                render={renderCallback}
            />
        {/* </LabelWrapper> */}
        <VFormErrorDisplay fieldName={fieldName} fieldError={fieldError} suppressed={fieldSuppressError} />
        {/* {isError && !fieldSuppressError && <VFormErrorText formError={extractedError} {...fieldError} />} */}
    </>
}

export function VFormLabel(props:{
    inputId: string,
    fieldLabel: React.ReactNode
    helpTip?: React.ReactNode
})
{
    return <>
        <label htmlFor={props.inputId}>{props.fieldLabel}</label>
        {props.helpTip && <span style={{paddingLeft:"0.5em"}}><VFormHelpTip tip={props.helpTip} /></span>}
    </>
}

export function VFormHelpTip(props: {
    tip: React.ReactNode
})
{
    return <OurTippy
        trigger="mouseenter click"
        interactive={true}
        content={props.tip}
        placement="top"
    >
        <span><FontAwesomeIcon icon={faQuestionCircle} color="#337ab7"/></span>
    </OurTippy>
}

//simple, consistent, way to indent a form field
export const VFormIndentDepentField:React.FC<PropsWithChildren> = ({children}) => {
    return <div style={{paddingLeft:"1em"}} >{children}</div>
}