import * as React from "react";

export const VPanel:React.FC<React.PropsWithChildren<{
    className?: string,
    heading?: JSX.Element|string
    footer?: JSX.Element|string
    passThroughChildrenOnly?: boolean   //if true, none of the panel is rendered, the children are rendered raw
    childrenInPanelBody?:boolean //default true
}>> = (props) =>                         //for certain situations, service item settings rendered in a panel body, with a router...needing the routig, but no render
{
    const classStr = "panel panel-default" + (props.className ? ` ${props.className}` : '');
    if(props.passThroughChildrenOnly)
        return <>{props.children}</>
    const { childrenInPanelBody=true } = props; //extract + set default
    return(
        <div className={classStr}>
            {props.heading && <div className="panel-heading">{props.heading}</div>}
            {childrenInPanelBody ? <div className="panel-body">{props.children}</div> : <>{props.children}</>}
            {props.footer && <div className="panel-footer">{props.footer}</div>}
        </div>
    )
}

//for use with VPanel and not using a body
export const VPanelHeader:React.FC<React.PropsWithChildren> = ({children}) => <div className="panel-heading">{children}</div>
export const VPanelBody:React.FC<React.PropsWithChildren> = ({children}) => <div className="panel-body">{children}</div>