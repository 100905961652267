import React, { ReactNode } from 'react'
import { Modal, Sizes } from 'react-bootstrap';
import { VForm } from '../forms/VForm';
import { VButton } from '../VButton';

import './VModal.css';

type CustomButtonColorScheme = 'draft'|'clockIn'|'clockOut';
export type VModalButton = {
    busy?:boolean
    disabled?:boolean
    label: string
    onClick: (btnIndex?:number) => void,
    type?: 'default'|'primary'|'danger'|'warning'|'info',
    customColorScheme?:CustomButtonColorScheme
}

const CustomButtonCss:Record<CustomButtonColorScheme,React.CSSProperties> = {
    'draft': {
        backgroundColor: 'rgb(127, 144, 159)',
        borderColor: 'rgb(127, 144, 159)'
    },
    'clockIn': {
        backgroundColor: 'green',
        borderColor: 'green'
    },
    'clockOut': {
        backgroundColor: 'red',
        borderColor: 'red'
    },
}
const FormWrapper:React.FC<React.PropsWithChildren<{
    isForm?:boolean
}>> = (props) =>
{
    if(props.isForm){
        return <VForm>{props.children}</VForm>
    }
    return <>{props.children}</>
}

export type VModalProps = {
    isForm?:boolean                 //if true. wrap the model in a VForm
    header?: ReactNode
    body: ReactNode
    isLoading?:boolean
    show: boolean
    // bodyClass?:string
    // bodyStyle?:React.CSSProperties    
    onHide: () => void
    footer?: React.ComponentType<{
        wrapperComponent?: React.ComponentType
    }>
    // footer?: React.FC<{wrapperComponent:JSX.Element}>             //mix footer with negativeAction/positiveAction sparingly - two footers - or respec this to be more useful
    negativeAction?: VModalButton
    positiveAction?: VModalButton|VModalButton[]
    includeCloseXButtonInHeader?: () => void
    footerWarning?: React.ReactElement|string|null
    container?:any                  //this is a ref to the element that will ultimately contain the modal (empty, puts it on the body) - it will actually contain the modal visually if .modal-container class is added to the container, 
                                    //this is used by VFormModal so as to put the modal into the form - could have render consequences though...need to test
    size?:Sizes
    bodyClassName?:string                               
}

export function VModal(props: VModalProps)
{
    // Can you believe react calls you with null props? I can't.
    if (!props)
        props = {} as unknown as VModalProps;
    const bodyClassName = props.bodyClassName;
    const isLoading = props.isLoading ?? false;
    // const bodyClass = (props.bodyClass ?? "") + (isLoading ? " vLoading" : "");
    // const bodyStyle = props.bodyStyle ?? {};
    const bodyClassList = [isLoading ? "vloading" : "VModalBody", bodyClassName];
    const bodyClass = bodyClassList.join(' '); 
    const bodyStyle:React.CSSProperties = {}; //minBody set in in VModalBody now
    
    const positiveButtons:VModalButton[]|undefined = props.positiveAction ? (Array.isArray(props.positiveAction) ? props.positiveAction : [props.positiveAction]) : undefined;
    return !props.body ? null : <Modal 
            animation={false}
            dialogClassName={props.size === 'medium'
                ? 'modal-md'
                : ''
            }
            bsSize={props.size === 'medium' 
                ? undefined 
                : (props.size ?? 'lg')} 
            show={props.show} 
            onHide={props.onHide}
            backdrop="static"
            container={props.container}
        >
        <FormWrapper isForm={props.isForm}>
            {props.header && 
            <Modal.Header>
                {props.includeCloseXButtonInHeader &&
                    <button type="button" className="close" onClick={props.includeCloseXButtonInHeader}><span aria-hidden="true">&times;</span></button>
                }
                {props.header}
            </Modal.Header>}
            <Modal.Body className={bodyClass} style={bodyStyle}>{props.body}</Modal.Body>    
            {props.footer && <props.footer wrapperComponent={Modal.Footer} />}
            {(props.negativeAction || props.positiveAction) && 
            <Modal.Footer>
                {props.negativeAction && 
                    <VButton 
                        style={
                            props.negativeAction.customColorScheme 
                            ? {float:"left", ...CustomButtonCss[props.negativeAction.customColorScheme]} 
                            : {float:"left"}
                        }
                        type={props.negativeAction.type ? props.negativeAction.type : 'danger'} 
                        onClick={props.negativeAction.onClick} 
                        busy={props.negativeAction.busy}
                        disabled={props.negativeAction.disabled}
                    >{props.negativeAction.label}</VButton>
                }
                {props.footerWarning && <span style={{color:"red"}}>{props.footerWarning}&nbsp;</span>}
                {positiveButtons && positiveButtons.map( (b,bIdx) => 
                    <VModalButton key={bIdx} button={b} clickValue={bIdx}/>                     
                )}                
                <div style={{clear:"both"}}></div>
            </Modal.Footer>}
        </FormWrapper>
    </Modal>
}

export function VModalButton<T extends number|undefined>({
    button:b,
    clickValue
}:{
    button:VModalButton,
    clickValue?:T
})
{
    return <VButton 
        type={b.type ? b.type : 'primary'} 
        onClick={() => b.onClick(clickValue)} 
        busy={b.busy}
        disabled={b.disabled}
        style={b.customColorScheme ? {...CustomButtonCss[b.customColorScheme]} : {}}
    >{b.label}</VButton>
}