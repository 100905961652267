import React from 'react';

type ErrProps = React.PropsWithChildren<{
   errorMessage?:string
}>;
type ErrState = {
    hasError:boolean
    errorMessage?:string,
    isChunkLoadError?:boolean,
}

export default class ErrorBoundary extends React.Component<ErrProps,ErrState> {
    props:ErrProps;
    constructor(props:ErrProps) {
      super(props);
      this.props = props;
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error:any) {
      // Update state so the next render will show the fallback UI.
      let errMsg = '';
      if(error instanceof Error)
        errMsg = error.message;  

      return { 
          hasError: true,
          errorMessage: errMsg,
          isChunkLoadError: error.name === 'ChunkLoadError' //VLazy - couldn't find component, probably updated ui
        };
    }
  
    // componentDidCatch(error:any, errorInfo:any) {
        // console.error('Error Boundary triggered:', error, errorInfo);
    // }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <div className=""> 
            <div className="alert alert-danger">
                {this.state.isChunkLoadError ? 
                <>
                    <div><b>New UI Available</b></div>
                    <div>The web UI has been updated and a page reload is needed.  Reload the page, or click <a href="">here</a> to reload</div>
                </>
                    : <>
                        <div>
                            <b>Doh!  Something went wrong</b>
                        </div>
                        {this.state.errorMessage && <div>
                            {this.state.errorMessage}
                        </div>}
                    </>}
            </div>
        </div>
      }
  
      return this.props.children; 
    }
  }