
import Ajv, { AsyncSchema, SchemaObject,  Options as AjvOptions, DefinedError, AsyncValidateFunction, ErrorObject } from "ajv";

import AjvValidationError from 'ajv/dist/runtime/validation_error'
export { AjvValidationError }

// import AjvValidationError from 'ajv/lib/runtime/validation_error'
// export { AjvValidationError }
// import AjvValidationError = Ajv.ValidationError;

import addFormats from "ajv-formats";
const AjvKeywords = require("ajv-keywords/dist/index");
import { VeriClockValidationYupError, VeriClockValidationAjvError } from '../../../vericlock_api/src/validation/ApiValidationErrorResponse';

import * as yup from 'yup';
// import moment from "moment-timezone";
import * as ipaddr from 'ipaddr.js';
import moment from "moment-timezone";
import { guidRegexString } from "../../../vericlock_api/src/validation/Constants";

// export { ValidationError as AjvValidationError } from 'ajv/dist/compile/error_classes';

const validationOptions:AjvOptions = {
    removeAdditional: "all",
    allErrors: true,
    // strict: false,
    strict: "log",
    strictTypes: true,
    discriminator: true,
    // strictRequired: false ///TEMP
    // strictRequired: 'log'
    useDefaults: true
}

const ajv = new Ajv(validationOptions);
require("ajv-errors")(ajv); //augment with ajv-errors package
addFormats(ajv);
AjvKeywords(ajv, 'transform');
ajv.addKeyword({
    keyword: 'vcIpCidr',
    schema: false,
    validate: (data:any) => {
        if(typeof(data) !== 'string')
            return false;
        try {
            ipaddr.parseCIDR(data);
            return true; //no throw == success
        } catch (err) {
            return false;
        }
    },
    error: {
        message: 'invalid IPv4/IPv6 CIDR address Range'
    }
})


ajv.addKeyword({
    keyword: 'timezone',
    schema: false,
    validate: (data:any) => {
        if(typeof(data) !== 'string')
            return false;
        try {
            const validZone = !!moment.tz.zone(data); //null if invalid/not found
            return validZone; //no throw == success
        } catch (err) {
            return false;
        }
    },
    error: {
        message: 'invalid IANA timezone'
    }
})
// AjvErrors(ajv /*, {singleError: true} */)

// ajv.addKeyword({
//     keyword: 'isTimezone', 
//     type: 'string',
//     modifying: false,
//     schemaType: "boolean",
//     schema: false, // keyword value is not used, can be true
//     // valid: true, // always validates as true
//     validate: function(data, dataPath, parentData, parentDataProperty)
//     {
//         const good = moment.tz.zone(data) !== null;
//         // if(!good)
//         // {

//         // }
//         return true;
//     //   if (typeof data != 'string' && parentData) // or some other condition
//     //     parentData[parentDataProperty] = 'N/A';
//     },
//     error: {
//         message: 'must be a valid IANA timezone code'
//     }
//   });

export const CommonObjectSchema:AsyncSchema = {
    $async: true,
    $schema: "http://json-schema.org/draft-07/schema#",  
    type: "object",
}

const CommonTypes:SchemaObject = {
    "$id": 'http://www.vericlock.com/schema/common.json',
    // "$id": "VeriClock:employeeTypes",
    definitions: {
        ApiGuid: {
            description: "An ApiGuid - 128bit hexadecimal string with dashes",
            type: "string",
            pattern: guidRegexString,
            transform: ["trim", "toLowerCase"],
            errorMessage: {
                pattern: "must be a valid hex string in guid form, ie: 54e36451-c09f-4cb6-82ac-9b9f1184f9a1"
            },
        },
        // guidOrNull: {
        ApiGuidNullable: {
            description: "Guid or null value",
            anyOf: [
                { $ref: "#/definitions/ApiGuid"},
                { type: "null" }
            ]
        },
        FileRef: {
            description: "A stored file reference or null",
            type: "object",
            required: ["guid"],
            properties: {
                guid: { $ref: "#/definitions/ApiGuid" },
            },
        },
        // emailOrBlank: {
        EmailAddressBlankNullable: {
            description: "Email address, null, or blank string all valid",
            anyOf: [
                { type: "string", format:"email", transform: ["trim"] }, //email string
                { type: "string", maxLength: 0 },   
                { type: "null", },                   //null allowed on this route equiv to blank
            ],
            errorMessage: "Must be a valid email address or empty"
        },
        EmailAddress: {
            type: "string", 
            format:"email", 
            transform: ["trim"],
            errorMessage: "Must be a valid email address"
        },         
        DateOrNull: {
            description: "A Date in the format YYYY-MM-DD",
            anyOf: [ 
                { type: "string", format:"date" }, //uses ajv format 'date'
                // { type: "string", maxLength: 0 },
                { type: "null" } 
            ],
            errorMessage: "Date must be in the format YYYY-MM-DD"
        },
        Date: {
            description: "A Date in the format YYYY-MM-DD",
            type: "string", 
            format:"date",
            errorMessage: "Date must be in the format YYYY-MM-DD"
        },
        DateTime: {
            description: "A Date in the ISO format",
            type: "string", 
            format:"date-time",
            errorMessage: "Date must be in a valid ISO format"
        },
        DateTimeOrNull: {
            description: "A Date in the ISO format",
            anyOf: [
                { type: "string", format:"date-time" },
                { type: "null" } 
            ],
            errorMessage: "Date must be in a valid ISO format"
        },
        Time: {
            description: "A time in HH:mm format",
            type: "string", 
            // format:"time",
            "pattern": "^(?:[0-9]|[01][0-9]|2[0-3]):[0-5][0-9]$",
            errorMessage: "Time must be in a valid HH:mm format"
        },
        TimeOrBlank: {
            description: "A time in HH:mm format",
            type: "string", 
            // format:"time",
            "pattern": "^(?:[0-9]|[01][0-9]|2[0-3]):[0-5][0-9]$|^$",
            errorMessage: "Time must be in a valid HH:mm format or an empty string"
        },
        Weekday: {
            description: "Weekday in 3 letter text abbreviation",
            ...ourEnum(['sun','mon','tue','wed','thu','fri','sat']),
        },
        // Try not to use this.  Better the UI converts to ISO format.  I needed this for backwards compatability.
        GenericTimeOfDay: {
            description: "Time of Day format",
            type: "string",
            // 1-2 numbers followed optionally by :XX 1 to 2 times and then optional white space and AM or PM.
            // 23(:59)(:59)( am/PM)
            // Not perfect in that 24hr and AM/PM aren't exclusive.
            pattern: "^(2[0-3]|[01]?[0-9])(:[0-5][0-9])?(:[0-5][0-9])?(\\s[aApP][Mm])?$",
            errorMessage: "Time must be in a valid format"
        },
        HttpsUrl: {
            description: "Valid HTTPS Url",
            type: "string",
            pattern: "^https?://...",
            maxLength: 4000
        },
        SecureUrl: { //above in use with ? for the s
            description: "Valid HTTPS Url",
            type: "string",
            pattern: "^https?://...",
            maxLength: 4000
        },
        HexColor: {
            description: "24bit HEX Color",
            type: "string",
            pattern: "^[A-Fa-f0-9]{6}$",            
        },
        GenericCssColor: {
            description:"A CSS color string",
            type: "string",
            maxLength: 15,
            minLength: 3
        }
    }
}

//cycle through localizations and create a validator per localization...Or not, construct our own errors from pure error
ajv.addSchema(CommonTypes);

export const standardFourOptions = ['optional','required','disabled','employee'];
export function ourEnum( options: readonly (string|number|null)[], extraOptions?: { default?: any })
{
    return { 
        // type: ["string","number"], 
        // type: "string", //is number needed ever?
        enum: options, 
        errorMessage: `must be one of [${options.map(item => {
            if(item === null)
                return 'null';
            return `"${item.toString()}"`
        }).join(',')}]`,
        ...extraOptions
    }
}

function getOurAjvValidationEngine()
{
    return ajv;
}
const _magicAjvValidatorCache:WeakMap<AsyncSchema,AsyncValidateFunction> = new WeakMap();  //  Record<symbol, AsyncValidateFunction> = {};

function isValidatorForSchema<T>(validator:AsyncValidateFunction, schema:AsyncSchema):validator is AsyncValidateFunction<T>
{
    if(validator.schema === schema)
        return true;
    return false;
}
export function compileValidator<T>(schema:AsyncSchema):AsyncValidateFunction<T>
{
    if(!_magicAjvValidatorCache.has(schema)) {
        const ajv = getOurAjvValidationEngine();
        const compiledValidator = ajv.compile(schema);
        _magicAjvValidatorCache.set(schema, compiledValidator);
    }
    const validator = _magicAjvValidatorCache.get(schema);
    if(!validator) {
        const errMsg = 'Missing validator in weakmap for schema';
        console.error(errMsg);
        console.log(schema);
        throw new Error(errMsg);
    }
    
    if(!isValidatorForSchema<T>(validator, schema)) {
        const errMsg = 'Found validator for schema, but it does not match';
        console.error(errMsg);
        console.log(schema);
        throw new Error(errMsg);
    }
    
    return validator;
}

//extracts property names from a list of schemas, and returns a simple object:
// {
//     prop1: {}
//     prop2: {}
// }
//to be spread into the if: clause so the properties are NOT removed during IF evaluation
type EmptyObject = Record<string, Record<string,unknown>>
type SchemaLikeObj = { 
    properties?: Record<string, unknown>,
    if?:SchemaLikeObj,
    then?:SchemaLikeObj,
    else?:SchemaLikeObj,
    oneOf?:SchemaLikeObj[]
 } | AsyncSchema

//merges props from the schemas as empty props, for existance propegation
// with if/branching, props are removed if they aren't in the IF schema, which messes things up 
// so this ensures they will propegate, if present. 
export function extractParamsForAjvRemoveAdditionalFix(schemas:SchemaLikeObj[]):Record<string,EmptyObject>
{
    let props:Record<string,EmptyObject> = {};
    schemas.forEach(s => {
        _extractParamsForAjvRemoveAdditionalFix(s, props);
    })
    return props;
}
function _extractParamsForAjvRemoveAdditionalFix(schema:SchemaLikeObj, dest:Record<string,EmptyObject>)
{
    if(schema.properties)
        extractObjectKeysToEmptyObjects(schema.properties, dest);
    if(schema.if)
        _extractParamsForAjvRemoveAdditionalFix(schema.if,dest);
    if(schema.then)
        _extractParamsForAjvRemoveAdditionalFix(schema.then,dest);
    if(schema.else)
        _extractParamsForAjvRemoveAdditionalFix(schema.else,dest);
    if(schema.oneOf) {
        schema.oneOf.forEach((s:SchemaLikeObj) => {
            _extractParamsForAjvRemoveAdditionalFix(s,dest);
        });
    }
}
export function extractObjectKeysToEmptyObjects(obj:Record<string,unknown>, dest:Record<string,EmptyObject> )
{
    Object.keys(obj).forEach(prop => dest[prop] = {});
}
export function returnObjectKeysInEmptyObjects(obj:Record<string,unknown>)
{
    let props:Record<string,EmptyObject> = {};
    Object.keys(obj).forEach(prop => props[prop] = {});
    return props;
}
//mimmicing yup's ValidationError
declare type Params = Record<string, unknown>;

export function isAjvValidationError(err:any):err is AjvValidationError
{
    return err instanceof AjvValidationError; //Ajv.ValidationError;
}

function recursiveValidationErrorCopy(err:yup.ValidationError): VeriClockValidationYupError
{
    return {
        name: err.name,
        message: err.message,
        path: err.path,
        type: err.type,
        errors: err.errors,
        inner: err.inner.map(recursiveValidationErrorCopy),
        // value: err.value
    };
}
export function makeValidationErrorFromYupError(err: yup.ValidationError):VeriClockValidationYupError
{
    return recursiveValidationErrorCopy(err);
}

// /payrollItems/0/payrollValue
// => payrollItems[0].payrollValue //lodash style
// .payrollItems.0.payrollValue //rhf 7+
export function convertAjvPathToOurPath(s:string)
{
    if(s[0] === '/')
    {
       const str1 = s.slice(1);
       //replace array refs /234 => [234]
       let subRow = 0;
       const arrayMatch = str1.match(/\/(\d+)/);
       if(arrayMatch) {
            subRow = parseInt(arrayMatch[1]);
            if(isNaN(subRow))
                throw new Error(`unabled to decode subRow array index in path[${s}]`);
            if(arrayMatch.length > 2)
                throw new Error('two array values in ajv error - unexpected, should only have one at most');   
       }
       
       //replace remaining / with . 
       const path = str1.replace(/\//g, '.'); //replace
       return { 
           path,
           subRow
       }
    }
    else if(s === '')
    {
        //the root object
        return {
            path: '',
            subRow: 0
        }
    }
    //not sure what is going on
    throw new Error(`cannot convert convertAjvPathToOurPath(${s})`);
}

type ExtendedDefinedError = ErrorObject<"errorMessage", {
    errors: DefinedError[]
}> | DefinedError;
export function makeValidationErrorFromAjvError(err: AjvValidationError):VeriClockValidationAjvError[]
{
    const errResponses:VeriClockValidationAjvError[] = [];
    let addedErrorCount = 0;
    for(let i = 0; i < err.errors.length; i++)
    {
        let e = err.errors[i] as ExtendedDefinedError;
        let propertyPath:string;        
        if(e.keyword === 'if' || (e.keyword === 'errorMessage' && e.params.errors.length > 0 && e.params.errors[0].keyword === 'if'))
        {
            if(addedErrorCount > 0)
            {
                console.log(`skipping keyword[index:${i}]:if error, as there are ${addedErrorCount} error(s) already added`);
                console.log(e);
                continue;
            }
            else {
                console.error(`AJV error keyword[index:${i}][if] shows an error, but no additional errors were reported - unexpected`)
                console.log(e);
                console.log(err.errors);
                throw new Error('AJV error keyword[if] shows an error, but no additional errors were reported - unexpected')    
            }
        }
        else if(e.instancePath !== undefined)
        {        
            let propPath = e.instancePath;
            if(e.keyword === 'required')
            {
                propPath = propPath + '/' + e.params.missingProperty; //if it is a sub property of an object that IS present, need to perform this surgery, add on the path
            }
            else if(e.keyword === 'errorMessage' && e.params.errors.length > 0 && e.params.errors[0].keyword === 'required')
            {
                propPath = propPath + '/' + e.params.errors[0].params.missingProperty;
            }
            const { path } = convertAjvPathToOurPath(propPath);                    
            propertyPath = path;
        }
        else  //no dataPath, meaning the error is with a missing prop or other condition
        {
            if(e.keyword === 'required')
            {
                propertyPath = e.params.missingProperty;
            }   
            else if(e.keyword === 'errorMessage' && e.params.errors.length > 0 && e.params.errors[0].keyword === 'required')
            {
                propertyPath = e.params.errors[0].params.missingProperty;
            }
            else if(e.keyword === 'type' && e.instancePath === '')
            {
                // req.body is the wrong type.  Normally we're expecting an object and likely it's undefined in this case.
                propertyPath = 'body';
            }
            //errorMessage plugin creates errors of type keyword, and so if we have one for an if/else clause, this can detect it - in general, there should
            //have been another more meaningful error produced if an if clause has fired - so we ignore it.  We do detect if there was no other 
            //error (under the assumption they are FIRST in the error list before the 'if' entry)     
            // else if(e.keyword === 'if' || (e.keyword === 'errorMessage' && e.params.errors.length > 0 && e.params.errors[0].keyword === 'if'))
            // {
            //     if(addedErrorCount > 0)
            //     {
            //         console.log(`skipping keyword[index:${i}]:if error, as there are ${addedErrorCount} error(s) already added`);
            //         console.log(e);
            //         continue;
            //     }
            //     else {
            //         console.error(`AJV error keyword[index:${i}][if] shows an error, but no additional errors were reported - unexpected`)
            //         console.log(e);
            //         console.log(err.errors);
            //         throw new Error('AJV error keyword[if] shows an error, but no additional errors were reported - unexpected')    
            //     }
            // }
            else {
                console.error('AJV Error below, needs decoder added');
                console.log(e);
                throw new Error(`AJV error keyword[index:${i}][${e.keyword}] not supported yet - needs decoder`)
            }
        }
        const message = e.message ? e.message : 'Unexpected error with property: ' + propertyPath;
        // if(e.keyword === 'errorMessage')
        // {
        //     e.message 
        // }

        // const errMsg = e.message ? e.message : 'unknown message';
        
        const errResp:VeriClockValidationAjvError = { 
            path: propertyPath,
            message: message, 
            type: e.keyword,
            _ajv: e,
            // params?: Params;
            // inner: [],
        }
        
        errResponses.push(errResp);
        addedErrorCount++;              //Keep track of errors added - to see if the 'if' keyword went haywire        
    }            
        
    return errResponses;
}
