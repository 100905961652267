import React, { Suspense, useState, useEffect } from 'react';

import ErrorBoundary from './ErrorBoundary';


const DefaultDynoSuspenseFallback:React.FC<Record<string,never>> = function ()
{
    let [showLoading, setShowLoading] = useState(false);
    useEffect(() => {
        let timerId = window.setTimeout(() => {
            timerId = 0;
            setShowLoading(true); //waited long enough
        },5000);

        return () => {
            if(timerId) {
                clearTimeout(timerId);
            }
        }
    },[]);

    if(!showLoading)
        return null;

    return <div>Loading...</div>
}//<div>Loading...</div>; //todo - use a functional component, with a timer..if it is too slow, display something

const defaultDynoCompError = "Something went wrong loading the page, please refresh your browser and try again"
export function VLazyComponent({
    component,
    children, 
    loadError=defaultDynoCompError,
    // fallback=defaultDynoSuspenseFallback
}:React.PropsWithChildren<{
    loadError?:string
    component?:React.ReactElement
    // fallback?:JSX.Element
}>)
{
    if(children && component)
        throw new Error('Cannot have both an attribute component AND child components');
    const realChild = children ? children : component;

    return <ErrorBoundary errorMessage={loadError}>
        <Suspense fallback={<DefaultDynoSuspenseFallback />}>
            {realChild}
        </Suspense>
    </ErrorBoundary>
}